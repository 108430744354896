.fallback-spinner {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.app-loader {
		height: 100vh;
		flex-direction: column;
		.loading {
			margin-top: 1rem;
		}
	}

	.loading {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		border: 3px solid transparent;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		.effect-1,
		.effect-2,
		.effect-3 {
			width: 55px;
			height: 55px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-left: 3px solid rgba(20, 78, 151, 1);
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}

		.effect-1 {
			position: absolute;
			animation: rotate 1s ease infinite;
		}
		.effect-2 {
			position: absolute;
			animation: rotateOpacity 1s ease infinite 0.1s;
		}
		.effect-3 {
			-webkit-animation: rotateOpacity 1s ease infinite 0.2s;
			animation: rotateOpacity 1s ease infinite 0.2s;
		}

		.loading .effects {
			transition: all 0.3s ease;
		}
	}
}
// .fallback-logo {
//   position: absolute;
//   left: calc(50% - 45px);
//   top: 40%;
// }

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}
@keyframes rotateOpacity {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 0.1;
	}
	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
		opacity: 1;
	}
}

.suspense-loader {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 10000;
	flex-direction: column;
	background-color: rgba(255, 255, 255, 0.8);
}

.dark-layout .suspense-loader {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.5);
}
